.contacts--img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 320px;
  pointer-events: none;
  animation: rotate 5s linear infinite;
  transition: all;
}
.contacts--img:hover{
  /* background: url(../assets/footer-img2.png); */
  background-color: red;
  display: none;
}
.footer_contact_h {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.footer_h {
  letter-spacing: 4px;
  font-size: 45px;
}
.contacts-form {
  display: flex;
  flex: 0.6 1;
  width: 100%;
}
.contacts-form form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}
.form-input {
  height: 50px;
  box-sizing: border-box;
  border-radius: 50px;
  outline: none;
  margin-bottom: 2rem;
  padding: 0.7rem 0.7rem;
  border-radius: 20px;
  width: 100%;
  resize: none;
}
.input-container {
  width: 100%;
  position: relative;
}
.input_box {
  color: #eaeaea;
  border: 4px solid #7e4ba5;
  transition: border 0.2s ease-in-out;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  background-color: #212121;
}
.input_box:focus {
  border: 4px solid #9450ff;
}
.message_box {
  color: #eaeaea;
  border: 4px solid #7e4ba5;
  transition: border 0.2s ease-in-out;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  background-color: #212121;
}
.message_box:focus {
  border: 4px solid #9450ff;
}
.contacts-body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.submit-btn button {
  border: none;
  outline: none;
  width: 140px;
  height: 50px;
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.submitBtn {
  color: #212121;
  transition: 250ms ease-in-out;
  position: unset;
  margin-top: -2rem;

  background-color: #7e4ba5;
  margin: 0 auto;
}
.submitBtn:hover {
  color: #212121;
  transform: scale(1.08);
  background-color: #eaeaea;
}
.submit-btn button p {
  font-size: 18px;
  margin-top: 18px;
  font-family: var(--primaryFont);
}

.submit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.send-icon {
  font-size: 25px;
  transform-origin: center;
  /* transform: translate(5px,-3px) rotate(-30deg); */
}

.success-icon {
  font-size: 28px;
  transition: all 0.3s 0.8s ease-in-out;
}
.error_msg {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-bottom: 7px;
  position: absolute;
  left: 0;
  bottom: 0;
  min-height: 20px;
  backdrop-filter: blur(31px) !important;
  font-size: 0.875rem;
  color: rgb(241 192 255);
  animation-duration: 2.6s;
  animation-name: fadeInReveal;
}
.form-message {
  height: 111px;
  box-sizing: border-box;
  border-radius: 50px;
  outline: none;
  margin-bottom: 2rem;
  padding: 0.7rem 0.7rem;
  border-radius: 20px;
  width: 100%;
  resize: none;
}
.input-label {
  background-color: #212121;
  color: #7e4ba5;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding: 0 5px;
  font-size: 0.9rem;
  transform: translate(25px, 50%);
  display: inline-flex;
}

@media only screen and (max-width: 768px) {
  .contacts-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-input {
    height: 39px;
  }
  .form-input {
    margin-bottom: 0.45rem;
  }
  .contacts-form form {
    align-items: flex-end;
  }
  .footer_contact_h {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .submit-btn {
    align-self: center;
  }
  .contacts--img {
    width: 199px;
    opacity: 0.6;
    left: 113px;
  }
  .error_msg {
    position: inherit;
  }
  span.error_msg.message {
    position: absolute;
    margin-bottom: 6px;
  }
}
@media (min-width: 992px) and (max-width: 1380px) {
  .contacts--img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 240px;
  }
}
@media only screen and (max-width: 463px) {
  a.live_button.s {
    margin-left: 0.4rem;
  }
}
