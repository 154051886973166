@import url("https://fonts.googleapis.com/css?family=Bangers|Cinzel:400,700,900|Lato:100,300,400,700,900|Lobster|Lora:400,700|Mansalva|Muli:200,300,400,600,700,800,900|Open+Sans:300,400,600,700,800|Oswald:200,300,400,500,600,700|Roboto:100,300,400,500,700,900&display=swap");
/* Used Google Fonts */

@font-face {
  font-family: "Mansalva', cursive", "" Bangers ", cursive'";
  src: url("https://fonts.googleapis.com/css?family=Bangers|Cinzel:400,700,900|Lato:100,300,400,700,900|Lobster|Lora:400,700|Mansalva|Muli:200,300,400,600,700,800,900|Open+Sans:300,400,600,700,800|Oswald:200,300,400,500,600,700|Roboto:100,300,400,500,700,900&display=swap");
  font-display: swap;
}

nav {
  height: 14vh;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  transition: 0.32s ease-in-out;
  width: 100%;
  z-index: 9999;
}
nav.scrolled {
  height: 72px;
  padding: 0;
  backdrop-filter: blur(15px) !important;
  background-color: #1b1a2ea9 !important;
  box-shadow: 0 10px 10px 0 rgba(9, 5, 29, 0.171) !important;
}
nav > .container {
  align-items: baseline;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  padding: 0px;
}
.logo-holder {
  text-align: center;
}
.header_link {
  text-decoration: none;
}
.logo_container h3 {
  color: #fff;
  font-weight: 300;
  font-size: 40px;
  line-height: 0.6;
  font-family: "Bangers", cursive;
  letter-spacing: 5px;
  margin: 0px;
}
.logo_container p {
  font-size: 14px;
  margin-left: 0;
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
  font-style: italic;
  font-family: "Mansalva", cursive;
  position: relative;
  margin: 0px;
}
.logo_container p:before {
  position: absolute;
  content: "";
  width: 17px;
  height: 12px;
  background: rgb(196, 187, 187);
  left: 0px;
  top: 5px;
}
.logo_container p:after {
  position: absolute;
  content: "";
  width: 17px;
  height: 12px;
  background: rgb(196, 187, 187);
  right: 0px;
  top: 5px;
}
.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #9450ff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #402769;
}

nav .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav .navbar-toggler-icon:after,
nav .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav .navbar-toggler-icon:after {
  top: 8px;
}
nav [aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav [aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav [aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
.nav-links {
  display: flex;
  list-style: none;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  padding: 10px 12px 0px;
}
.nav-links li a {
  text-decoration: none;
  font-weight: 500;
  color: #fff !important;
  letter-spacing: 0.8px;
  font-size: 18px;
  display: inline-block;
  position: relative;
  opacity: 0.75;
  padding: 20px 10px;
  cursor: pointer;
  margin: 0px 20px;
}
.nav-links li a:hover,
.nav-links li a.active {
  opacity: 1;
}
.nav-links li a::before {
  width: 0%;
  bottom: 10px;
}
.nav-links li a:before {
  content: "";
  background-color: #e8ebef;
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}
.nav-links li a:hover:before {
  width: 100%;
}

@media screen and (max-width: 768px) {
  nav {
    align-items: center;
    height: 10vh;
  }
  nav.scrolled {
    height: 54px;
  }
  .logo_container h3 {
    font-size: 35px;
  }

  .hamburger {
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    display: block;
  }
  .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    clip-path: circle(100px at 90% -10%);
    -webkit-clip-path: circle(100px at 90% -10%);
    transition: all 0.8s ease-out;
    pointer-events: none;
    padding: 50px;
  }
  .nav-links.open {
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
    background: rgba(0, 0, 0, 0.9);
  }
  .landing {
    flex-direction: column;
  }
  .nav-links li {
    opacity: 0;
  }
  .nav-links li a {
    font-size: 25px;
  }
  .nav-links li:nth-child(1) {
    transition: all 0.5s ease 0.2s;
  }
  .nav-links li:nth-child(2) {
    transition: all 0.5s ease 0.4s;
  }
  .nav-links li:nth-child(3) {
    transition: all 0.5s ease 0.6s;
  }
  .nav-links li:nth-child(4) {
    transition: all 0.5s ease 0.8s;
  }
  li.fadelinks {
    opacity: 1;
  }
}
