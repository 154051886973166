.skills {
    padding: 0 0 50px 0;
    position: relative;
    background-image: url(../assets/footer-bg.webp);
    background-position: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.5s;
    opacity: 1;
  }
  
  .tecks {
    background: #000000;
    border-top-left-radius: 64px;
    border-top: 6px solid #9450ff;
    opacity: 0.9;
    text-align: center;
    padding: 22px 6px;
  }
  .skill_box {
    background: #151515;
    border-bottom-right-radius: 64px;
    border-bottom-color: #6930c3;
    opacity: 0.9;
    text-align: center;
    padding: 31px 6px;
  }
  .skills h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    padding: 10px;
    font-family: "Poppins", sans-serif !important;
  }
  .skills-slider {
    width: 90%;
    margin: 0 auto;
    position: relative;
  }
  .skill_img {
    margin: 0 auto 15px auto;
  }
  .skill_item {
    text-align: center;
    border-radius: 10px;
    background-color: none;
    border: none;
    padding: 23px;
    margin: 8px 20px 20px 40px;
    transition: all 0.5s ease;
  }
  .skill_name {
    text-align: center;
    font-size: 20px;
    justify-content: center;
    margin-top: 10px;
  }
  
  .skill_item:hover {
    -webkit-box-shadow: 0px 0px 25px 0 #6930c3,
      -6px 10px 0 0 rgba(40, 40, 40, 0.75), -12px 20px 0 0 rgba(45, 45, 45, 0.5);
    box-shadow: 0px 0px 25px 0 #6930c3, -6px 10px 0 0 rgba(40, 40, 40, 0.75),
      -12px 20px 0 0 rgba(45, 45, 45, 0.5);
  }
  .skill-p {
    padding: 15px;
  }
  .skills_images {
    width: 95%;
    object-position: center center;
  }
  .skill_type {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 7px 46px 6px 53px;
    border-top: 6px solid #9450ff;
    border-radius: 15px;
    text-align: center;
    transition: all 0.5s ease;
  }
  .skill_type:hover {
    box-shadow: 0px 0px 25px 0 #f9f8fa, -6px 10px 0 0 rgba(40, 40, 40, 0.75),
      -12px 20px 0 0 rgba(45, 45, 45, 0.5);
    transform: scale(1.1);
  }
  .skill_type {
    color: #fff;
    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px rgb(255, 255, 255),
      0 0 42px #8d0bc0, 0 0 82px #bc13fe, 0 0 92px #7700a7, 0 0 102px #350149,
      0 0 151px #5a0404;
  }

  @media only screen and (max-width: 768px) {
    .skill_img {
      width: 100% !important;
    }
    .skill_name {
      text-align: center;
      font-size: 22px;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 104px;
      left: 15px;
    }
  }