.banner {
  height: 100vh;
  background: url(../assets/banner-bg20.webp) repeat top center;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  position: relative;
  color: #fff;
}
.banner > .container {   
  align-items: baseline;
  flex-wrap: inherit;
  justify-content: space-between;
  padding-top: 30vh;
}
.main-text {
  max-width: 617px;
  overflow: hidden;
  font-family: monospace;
}
.main-text h1 {
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.8px;
  font-size: 40px;
  margin-bottom: 4px;
  padding: 3px 4px;
  font-size: 2.8rem;
  white-space: nowrap;
}
.animated-text {
  animation-duration: 2.6s;
  animation-fill-mode: both;
  animation-name: fadeInRight;
}
.animated-text.first {
  animation-timing-function: cubic-bezier(0.65, 0.1, 0.65, 1);
}
.animated-text.second {
  animation-timing-function: cubic-bezier(0.9, 0.1, 0.65, 1);
}
.connect-box {
  padding: 19px 0px 2px 8px;
  overflow: hidden;
  animation-duration: 2.6s;
  animation-fill-mode: both;
  animation-name: fadeInReveal;
}
#fadeInP {
  animation-delay: 2500ms;
  animation-iteration-count: 1;
  animation-name: fadeInReveal;
  animation-fill-mode: both;
  animation-duration: 1000ms;
}
.connect-box button {
  animation-name: fadeInReveal;
  animation-fill-mode: both;
  animation-delay: 3000ms;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
}
.banner p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner img {
  animation: upAndDown 3s linear infinite;
}
.banner button {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  display: inline-block;
  position: relative;
  padding: 0.5rem 1.2rem;
  font-weight: 700;
  font-size: 20px;
  margin-top: 40px;
  letter-spacing: 0.8px;
  align-items: center;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #bb99c0;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
.banner button a {
  text-decoration: none;
  color: #fff;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 20px;
}
.banner button:hover {
  box-shadow: inset 400px 0 0 0 #ffffff;
  color: black;
}

@media screen and (max-width: 768px) {
  .banner h1 {
    font-size: 32px;
    letter-spacing: -3px;
  }
  .banner {
    height: 104vh;
    padding: 78px 0 100px 0;
    background: url(../assets/banner-bg10.webp) repeat top center;
  }
  .banner button {
    margin-top: 26px;
  }
  .main-text {
    font-size: 12px !important;
    line-height: 80%;
    margin-top: 100px;
  }
  .connect-box {
    text-align: center;
  }
  .banner > .container {
    align-items: baseline;
    flex-wrap: inherit;
    justify-content: space-between;
    padding-top: 0;
  }
}