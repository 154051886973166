* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-padding-top: 75px;
}
body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: "Centra", sans-serif;
}
::-webkit-scrollbar {
  width: 10px;
  scroll-behavior: smooth;
}
::-webkit-scrollbar-thumb {
  background-color: #9450ff;
  border: 0 solid rgb(179, 151, 151);
  border-radius: 10px;
}
.logo {
  width: 100%;
  height: auto;
  vertical-align: baseline;
}

.go_up_button {
  position: fixed;
  width: 100%;
  left: 50%;
  bottom: 40px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: green;
}
.top-to-btm {
  position: relative;
}
.icon-position {
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 3%;
  right: 2%;
  z-index: 20;
}
.icon-style {
  border: 2px solid #7e4ba5;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  color: #fff;
  cursor: pointer;
  animation: upAndDown 3s linear infinite;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-style:hover {
  animation: none;
  background: #fff;
  color: #551b54;
  border: 2px solid #551b54;
}
/* keyframes animations */
@keyframes rotate {
  0% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(-20px);
  }
}
@keyframes upAndDown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInReveal {
  0% {
    opacity: 0;
    transform: translate3d(-30px, 0px, 0px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fly {
  10% {
    transform: rotate(0deg);
  }
  20% {
    transform: translateX(-10px);
  }
  70% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(80px);
  }
}
